import React, { useState, useEffect, useRef, memo } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Drawer,
  Tooltip,
} from "@mui/material";
import { 
  Add as AddIcon, 
  Delete as DeleteIcon, 
  Send as SendIcon,
  Edit as EditIcon,
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
  Chat as ChatIcon,
  Warning as WarningIcon,
} from "@mui/icons-material";
import generalApi from "../api/generalApi";
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatDistanceToNow, format } from 'date-fns';
import { useLimits } from "../context/LimitsContext";

const TimeAgo = ({ timestamp, sx = {} }) => {
  const date = new Date(timestamp * 1000);
  const relativeTime = formatDistanceToNow(date, { addSuffix: true });
  const fullDate = format(date, 'PPpp'); // e.g. "Jan 1, 2024, 12:00 PM"

  return (
    <Typography
      variant="caption"
      color="text.secondary"
      title={fullDate}
      sx={{ 
        cursor: 'pointer',
        ...sx
      }}
    >
      {relativeTime}
    </Typography>
  );
};

const MessageInput = memo(({ onSendMessage, loading, inputRef }) => {
  const [newMessage, setNewMessage] = useState("");

  const handleSendMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();  // Prevent any parent handlers
    if (!newMessage.trim() || loading) return;  // Prevent sending if loading
    onSendMessage(newMessage);
    setNewMessage("");
    // Force focus back
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();  // Prevent any parent handlers
      if (!loading) {  // Only send if not loading
        handleSendMessage(e);
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSendMessage}
      onClick={(e) => e.stopPropagation()}  // Prevent click propagation
      sx={{
        p: 2,
        pt: 4,
        pb: 3,
        mt: 3,
        bgcolor: "#ffffff",
        borderTop: "1px solid",
        borderColor: "#e0e0e0",
        pointerEvents: 'auto',  // Ensure input remains interactive
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
        <TextField
          inputRef={inputRef}
          fullWidth
          multiline
          maxRows={4}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          placeholder="Type your message..."
          disabled={false}  // Never disable the input
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: 2,
            },
            "& .MuiInputBase-input": {
              pointerEvents: 'auto',  // Ensure input remains interactive
            }
          }}
        />
        <Tooltip title="Each message costs 0.2 Credits" arrow placement="top">
          <span>
            <IconButton 
              color="primary"
              onClick={handleSendMessage}
              disabled={!newMessage.trim() || loading}  // Keep the send button disabled during loading
              sx={{
                bgcolor: "#9c27b0",
                color: "white",
                width: 48,
                height: 48,
                borderRadius: '50%',
                "&:hover": { bgcolor: "#7b1fa2" },
                "&.Mui-disabled": {
                  bgcolor: "#e0e0e0",
                  color: "#9e9e9e"
                }
              }}
            >
              <SendIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
});

const Chat = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { fetchLimits } = useLimits();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isCreatingThread, setIsCreatingThread] = useState(false);
  const [newThreadDialog, setNewThreadDialog] = useState(false);
  const [newThreadTitle, setNewThreadTitle] = useState("New Chat");
  const [isRenaming, setIsRenaming] = useState(false);
  const [threadToRename, setThreadToRename] = useState(null);
  const [newTitle, setNewTitle] = useState("");
  const messagesEndRef = useRef(null);
  const newThreadInputRef = useRef(null);
  const messageInputRef = useRef(null);
  const MAX_THREADS = 20;
  const WARNING_THRESHOLD = 18;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    loadThreads();
  }, []);

  useEffect(() => {
    if (selectedThread) {
      // Only load messages if this is an existing thread (has lastMessageAt)
      if (selectedThread.lastMessageAt) {
        loadMessages(selectedThread.threadId);
      }
    }
  }, [selectedThread]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (newThreadDialog) {
      setTimeout(() => {
        newThreadInputRef.current?.select();
      }, 100);
    }
  }, [newThreadDialog]);

  const loadThreads = async (showLimitToasts = false) => {
    try {
      const response = await generalApi.chat.getThreads();
      if (Array.isArray(response)) {
        setThreads(response);
        if (showLimitToasts) {
          if (response.length === MAX_THREADS) {
            toast.warning(`Thread limit reached (${MAX_THREADS}/${MAX_THREADS} threads)`);
          } else if (response.length >= WARNING_THRESHOLD) {
            toast.warning(`You are approaching the thread limit (${response.length}/${MAX_THREADS} threads)`);
          }
        }
      } else {
        setThreads([]);
      }
    } catch (error) {
      console.error('Failed to load threads:', error);
      toast.error('Failed to load chats');
      setThreads([]);
    }
  };

  const loadMessages = async (threadId) => {
    try {
      setLoading(true);
      const response = await generalApi.chat.getMessages(threadId);
      if (Array.isArray(response)) {
        const validMessages = response
          .filter(msg => 
            msg.content && 
            Array.isArray(msg.content) && 
            msg.content.length > 0 &&
            msg.content[0].type === 'text' &&
            msg.content[0].text?.value
          )
          .sort((a, b) => a.created_at - b.created_at);
        setMessages(validMessages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error('Failed to load messages:', error);
      toast.error('Failed to load messages');
      setMessages([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateThread = async () => {
    try {
      setIsCreatingThread(true);
      const title = newThreadTitle.trim() || "New Chat";
      const response = await generalApi.chat.createThread(title);
      if (response?.threadId) {
        setSelectedThread(response);
        setMessages([]); 
        setNewThreadDialog(false);
        setNewThreadTitle("");
        // Pass true to show limit toasts when creating a thread
        await loadThreads(true);
      } else {
        toast.error('Invalid response from server');
      }
    } catch (error) {
      console.error('Failed to create thread:', error);
      toast.error('Failed to create new chat');
    } finally {
      setIsCreatingThread(false);
    }
  };

  const handleDeleteThread = async () => {
    if (!threadToDelete) return;

    try {
      setIsDeleting(true);
      await generalApi.chat.deleteThread(threadToDelete.threadId);
      setThreads(threads.filter(t => t.threadId !== threadToDelete.threadId));
      if (selectedThread?.threadId === threadToDelete.threadId) {
        setSelectedThread(null);
        setMessages([]);
      }
      setDeleteDialogOpen(false);
      setThreadToDelete(null);
    } catch (error) {
      console.error('Failed to delete thread:', error);
      toast.error('Failed to delete chat');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSendMessage = async (messageText) => {
    if (!messageText.trim() || !selectedThread) return;
    
    // Add user message immediately
    const userMessage = {
      id: Date.now().toString(),
      object: "thread.message",
      created_at: Math.floor(Date.now() / 1000),
      thread_id: selectedThread.threadId,
      role: 'user',
      content: [{ 
        type: 'text', 
        text: { value: messageText }
      }],
      metadata: {},
      attachments: []
    };
    
    // Set messages with user message
    const newMessages = [...messages, userMessage];
    setMessages(newMessages);
    setLoading(true);

    try {
      // Update thread title if it's a new thread (no lastMessageAt)
      if (!selectedThread.lastMessageAt) {
        const title = messageText.split(' ').slice(0, 5).join(' ') + '...';
        const updatedThread = { 
          ...selectedThread, 
          title, 
          lastMessage: messageText,
          lastMessageAt: new Date().toISOString()
        };
        setSelectedThread(updatedThread);
        setThreads(threads.map(t => 
          t.threadId === selectedThread.threadId ? updatedThread : t
        ));
      }

      // Send message and get response
      const response = await generalApi.chat.sendMessage(
        selectedThread.threadId,
        messageText
      );

      // Add assistant message
      if (response?.message) {
        const assistantMessage = {
          id: `${Date.now()}-assistant`,
          object: "thread.message",
          created_at: Math.floor(Date.now() / 1000),
          thread_id: selectedThread.threadId,
          role: 'assistant',
          content: [{ 
            type: 'text', 
            text: { value: response.message }
          }],
          metadata: {},
          attachments: []
        };

        setMessages(prev => [...prev, assistantMessage]);
      }
      
      // Fetch updated limits after message is sent
      await fetchLimits();
      
      // Pass false to not show limit toasts when sending a message
      await loadThreads(false);
    } catch (error) {
      toast.error('Failed to send message');
      console.error('Send message error:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderMessage = (content) => {
    if (!content.includes('`') && !content.includes('#') && !content.includes('*')) {
      // For plain text, split by newlines and create paragraphs
      return content.split('\n').map((line, i) => (
        <Typography key={i} sx={{ mb: line ? 1 : 0 }}>
          {line || '\u00A0'}
        </Typography>
      ));
    }

    return (
      <ReactMarkdown
        components={{
          p: ({ children }) => (
            <Typography sx={{ mb: 1 }}>{children}</Typography>
          ),
          code({node, inline, className, children, ...props}) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                style={tomorrow}
                language={match[1]}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          }
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  const StatusIndicator = () => (
    <Box sx={{ 
      position: 'absolute', 
      top: 16,
      right: 16,
      display: 'flex', 
      alignItems: 'center',
      gap: 1,
      bgcolor: 'rgba(255, 255, 255, 0.9)',
      padding: '4px 12px',
      borderRadius: 8,
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      zIndex: 10
    }}>
      <Box sx={{ 
        width: 8,
        height: 8,
        borderRadius: '50%',
        bgcolor: '#4caf50'
      }} />
      <Typography variant="caption" color="text.secondary">
        Connected
      </Typography>
    </Box>
  );

  const LoadingDots = () => (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: 24 }}>
      <Box
        component="span"
        sx={{
          width: 6,
          height: 6,
          borderRadius: '50%',
          bgcolor: '#9c27b0',
          animation: 'pulse 1s infinite',
          '@keyframes pulse': {
            '0%': { opacity: 0.3 },
            '50%': { opacity: 1 },
            '100%': { opacity: 0.3 }
          }
        }}
      />
      <Box component="span" sx={{ width: 6, height: 6, borderRadius: '50%', bgcolor: '#9c27b0', animation: 'pulse 1s infinite', animationDelay: '0.2s' }} />
      <Box component="span" sx={{ width: 6, height: 6, borderRadius: '50%', bgcolor: '#9c27b0', animation: 'pulse 1s infinite', animationDelay: '0.4s' }} />
    </Box>
  );

  const handleRenameThread = async () => {
    if (!threadToRename || !newTitle.trim()) return;

    try {
      setIsRenaming(true);
      const response = await generalApi.chat.renameThread(threadToRename.threadId, newTitle);
      
      // Update local state
      const updatedThread = { ...threadToRename, title: response.title };
      setThreads(threads.map(t => 
        t.threadId === threadToRename.threadId ? updatedThread : t
      ));
      if (selectedThread?.threadId === threadToRename.threadId) {
        setSelectedThread(updatedThread);
      }
      
      setThreadToRename(null);
      setNewTitle("");
      toast.success('Thread renamed successfully');
    } catch (error) {
      console.error('Failed to rename thread:', error);
      toast.error('Failed to rename thread');
    } finally {
      setIsRenaming(false);
    }
  };

  const handleNewThreadKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleCreateThread();
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const chatsList = (
    <Paper
      elevation={0}
      sx={{
        width: "320px",
        minWidth: "320px",
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "hidden",
        bgcolor: "#ffffff",
        border: "1px solid",
        borderColor: "#e0e0e0",
        pt: 2,
      }}
    >
      <Box sx={{ p: 2, pb: 0 }}>
        <Typography
          variant="subtitle1"
          sx={{
            color: "#333",
            mb: 2,
            fontWeight: 500,
          }}
        >
          Latest Chats
        </Typography>
      </Box>
      <List
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          px: 2,
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "#9333EA",
          },
        }}
      >
        {threads.length > 0 ? (
          threads.map((thread) => (
            <ListItem
              key={thread.threadId}
              button
              selected={selectedThread?.threadId === thread.threadId}
              onClick={() => setSelectedThread(thread)}
              sx={{
                mb: 1,
                borderRadius: 1,
                "&.Mui-selected": {
                  backgroundColor: "#f0f0f0",
                },
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                  "& .rename-icon": {
                    opacity: 1,
                  },
                },
              }}
            >
              <ListItemText
                primary={
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 1, mr: 1 }}>
                      <Typography sx={{ color: "#333", fontSize: "0.9rem" }}>
                        {thread.title}
                      </Typography>
                      <IconButton
                        size="small"
                        className="rename-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          setThreadToRename(thread);
                          setNewTitle(thread.title);
                        }}
                        sx={{
                          ml: 1,
                          opacity: 0,
                          transition: 'opacity 0.2s',
                          padding: '4px',
                          '& svg': {
                            fontSize: '0.9rem',
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    {thread.lastMessageAt && (
                      <TimeAgo 
                        timestamp={new Date(thread.lastMessageAt).getTime() / 1000}
                        sx={{ ml: 1, fontSize: '0.75rem' }}
                      />
                    )}
                  </Box>
                }
                secondary={thread.lastMessage}
                secondaryTypographyProps={{
                  sx: {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                  }
                }}
              />
              <IconButton
                edge="end"
                onClick={(e) => {
                  e.stopPropagation();
                  setThreadToDelete(thread);
                  setDeleteDialogOpen(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))
        ) : (
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            height: '100%',
            color: 'text.secondary'
          }}>
            <Typography>No chats to show</Typography>
          </Box>
        )}
      </List>
    </Paper>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 2,
        mt: 3,
        height: "calc(100vh - 100px)",
        bgcolor: "#f5f5f5",
        overflow: "hidden",
      }}
    >
      <Box sx={{ 
        display: "flex", 
        flexDirection: "column",
        gap: 2,
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography
              variant="h5"
              sx={{
                color: "#333",
                fontWeight: 600,
                mb: 2,
              }}
            >
              NSL Chat AI
            </Typography>
            <Tooltip 
              title="This is an experimental feature. While we strive for accuracy, please verify any critical advice. Niche Toolbox is not responsible for any inadequate suggestions."
              arrow
              placement="right"
            >
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 0.5,
                  fontSize: '0.75rem',
                  color: '#ffc107',
                  bgcolor: 'rgba(255, 193, 7, 0.1)',
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  cursor: 'help',
                  mb: 2,
                  '&:hover': {
                    bgcolor: 'rgba(255, 193, 7, 0.15)',
                  },
                }}
              >
                <WarningIcon sx={{ fontSize: '1rem' }} />
                Experimental
              </Box>
            </Tooltip>
          </Box>
          {!isMobile && (
            <Button
              variant="contained"
              startIcon={isCreatingThread ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
              onClick={() => setNewThreadDialog(true)}
              disabled={isCreatingThread}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                mb: 2,
              }}
            >
              New Chat
            </Button>
          )}
        </Box>
        
        {isMobile && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
          }}>
            <Button
              startIcon={<ChatIcon sx={{ fontSize: '1.5rem' }} />}
              onClick={handleDrawerToggle}
              variant="text"
              sx={{
                color: 'text.secondary',
                textTransform: 'none',
                fontSize: '1.1rem',
                '&:hover': {
                  bgcolor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              Latest Chats
            </Button>
            <Button
              variant="contained"
              startIcon={isCreatingThread ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
              onClick={() => setNewThreadDialog(true)}
              disabled={isCreatingThread}
              sx={{
                bgcolor: "#9c27b0",
                "&:hover": { bgcolor: "#7b1fa2" },
                fontSize: '1.1rem',
                textTransform: 'none',
              }}
            >
              New Chat
            </Button>
          </Box>
        )}
        
        {!isMobile && (
          <Button
            variant="contained"
            startIcon={isCreatingThread ? <CircularProgress size={20} color="inherit" /> : <AddIcon />}
            onClick={() => setNewThreadDialog(true)}
            disabled={isCreatingThread}
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
              position: 'absolute',
              right: 24,
              top: 24,
            }}
          >
            New Chat
          </Button>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 2,
          flexGrow: 1,
          height: "100%",
          overflow: "hidden",
          position: 'relative',
        }}
      >
        <StatusIndicator />
        
        {isMobile ? (
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better mobile performance
            }}
            PaperProps={{
              sx: {
                width: "320px",
                height: "100%",
                bgcolor: "#ffffff",
              }
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              p: 2,
              borderBottom: '1px solid',
              borderColor: 'rgba(0, 0, 0, 0.1)'
            }}>
              <IconButton onClick={handleDrawerToggle}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ ml: 2 }}>
                Latest Chats
              </Typography>
            </Box>
            {chatsList}
          </Drawer>
        ) : (
          chatsList
        )}

        <Paper
          elevation={0}
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflow: "hidden",
            bgcolor: "#ffffff",
            border: "1px solid",
            borderColor: "#e0e0e0",
          }}
        >
          {selectedThread ? (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  overflowY: "auto",
                  p: 3,
                  pt: 6,
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "4px",
                    backgroundColor: "#9333EA",
                  },
                }}
              >
                {messages.map((message, index) => (
                  <Box
                    key={message.id}
                    sx={{
                      display: "flex",
                      mb: 3,
                      mt: index === 0 ? 4 : 0,
                      justifyContent: message.role === "user" ? "flex-end" : "flex-start",
                    }}
                  >
                    {message.role !== "user" && (
                      <Avatar
                        src="/static/media/mainLogo.54127b07ab64bbaa81da.png"
                        sx={{ width: 40, height: 40, mr: 2 }}
                      />
                    )}
                    <Box sx={{ maxWidth: "70%" }}>
                      <Paper
                        sx={{
                          p: 2,
                          bgcolor: message.role === "user" ? "#9c27b0" : "#ffffff",
                          color: message.role === "user" ? "#ffffff" : "inherit",
                          borderRadius: 2,
                        }}
                      >
                        {message.content[0]?.text?.value ? 
                          renderMessage(message.content[0].text.value) :
                          <Typography>Message content unavailable</Typography>
                        }
                      </Paper>
                      <Box sx={{ 
                        mt: 0.5, 
                        display: 'flex', 
                        justifyContent: message.role === "user" ? "flex-end" : "flex-start"
                      }}>
                        <TimeAgo 
                          timestamp={message.created_at}
                          sx={{ 
                            color: 'text.secondary',
                            fontSize: '0.75rem'
                          }}
                        />
                      </Box>
                    </Box>
                    {message.role === "user" && (
                      <Avatar sx={{ width: 40, height: 40, ml: 2 }} />
                    )}
                  </Box>
                ))}
                {loading && (
                  <Box
                    sx={{
                      display: "flex",
                      mb: 3,
                      alignItems: "flex-start",
                    }}
                  >
                    <Avatar
                      src="/static/media/mainLogo.54127b07ab64bbaa81da.png"
                      sx={{ width: 40, height: 40, mr: 2 }}
                    />
                    <Paper
                      sx={{
                        p: 2,
                        bgcolor: "#ffffff",
                        borderRadius: 2,
                      }}
                    >
                      <LoadingDots />
                    </Paper>
                  </Box>
                )}
                <div ref={messagesEndRef} />
              </Box>
              <MessageInput 
                onSendMessage={handleSendMessage}
                loading={loading}
                inputRef={messageInputRef}
              />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                Select a chat or create a new one
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => !isDeleting && setDeleteDialogOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 500,
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          pb: 2
        }}>
          Delete Chat
        </DialogTitle>
        <DialogContent sx={{ mt: 2, pb: 3 }}>
          Are you sure you want to delete this chat? This action cannot be undone.
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          p: 2,
          gap: 1
        }}>
          <Button 
            onClick={() => setDeleteDialogOpen(false)} 
            disabled={isDeleting}
            variant="outlined"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              px: 3
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteThread} 
            color="error"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={20} /> : null}
            variant="contained"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              px: 3
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={newThreadDialog}
        onClose={() => !isCreatingThread && setNewThreadDialog(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 500,
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          pb: 2
        }}>
          Create New Chat
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <TextField
            inputRef={newThreadInputRef}
            autoFocus
            margin="dense"
            label="Chat Title"
            fullWidth
            value={newThreadTitle}
            onChange={(e) => setNewThreadTitle(e.target.value)}
            onKeyPress={handleNewThreadKeyPress}
            placeholder="Enter chat title or leave empty for default"
            disabled={isCreatingThread}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          p: 2,
          gap: 1
        }}>
          <Button 
            onClick={() => setNewThreadDialog(false)} 
            disabled={isCreatingThread}
            variant="outlined"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              px: 3
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleCreateThread}
            disabled={isCreatingThread}
            startIcon={isCreatingThread ? <CircularProgress size={20} /> : null}
            variant="contained"
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
              borderRadius: 1,
              textTransform: 'none',
              px: 3
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!threadToRename}
        onClose={() => !isRenaming && setThreadToRename(null)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 500,
            borderRadius: 2,
          }
        }}
      >
        <DialogTitle sx={{ 
          borderBottom: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          pb: 2
        }}>
          Rename Chat
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <TextField
            autoFocus
            margin="dense"
            label="New Title"
            fullWidth
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            disabled={isRenaming}
            error={!newTitle.trim()}
            helperText={!newTitle.trim() ? "Title is required" : ""}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ 
          borderTop: '1px solid',
          borderColor: 'rgba(0, 0, 0, 0.1)',
          p: 2,
          gap: 1
        }}>
          <Button 
            onClick={() => setThreadToRename(null)} 
            disabled={isRenaming}
            variant="outlined"
            sx={{
              borderRadius: 1,
              textTransform: 'none',
              px: 3
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleRenameThread}
            disabled={isRenaming || !newTitle.trim()}
            startIcon={isRenaming ? <CircularProgress size={20} /> : null}
            variant="contained"
            sx={{
              bgcolor: "#9c27b0",
              "&:hover": { bgcolor: "#7b1fa2" },
              borderRadius: 1,
              textTransform: 'none',
              px: 3
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Chat; 