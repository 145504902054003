import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  TextField,
  Grid,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import {
  ArrowRight,
  Search,
  X,
  Flame,
  Sparkles,
  Wand2,
  Download,
  PlusCircle,
  ArrowLeft,
} from "lucide-react";
import MemeCard from "./MemeCard";
import MemeBrowseCanvas from "./MemeBrowseEditor";
import BrowseMemeCanvas from "./BrowseMemeCanvas";
import debounce from "lodash/debounce";
import generalApi from "../../../api/generalApi";
import LanguageSelector, { languages } from "../../common/LanguageSelector";
import ProjectSelector from "../../Project/ProjectSelector";
import { toast } from "react-toastify";
import { useLimits } from "../../../context/LimitsContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

// Define fontOptions outside the component
const fontOptions = [
  { value: '"Arial Black", Impact, sans-serif', label: "Impact (Default)" },
  { value: "Arial, sans-serif", label: "Arial" },
  { value: "Comic Sans MS, cursive", label: "Comic Sans" },
  { value: "Times New Roman, serif", label: "Times New Roman" },
  { value: "Courier New, monospace", label: "Courier" },
  { value: "Georgia, serif", label: "Georgia" },
  { value: "Verdana, sans-serif", label: "Verdana" },
  { value: "Helvetica, Arial, sans-serif", label: "Helvetica" },
];

const BrowseMemes = () => {
  const [popularMemes, setPopularMemes] = useState([]);
  const [otherMemes, setOtherMemes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMeme, setSelectedMeme] = useState(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [selectedTextIndex, setSelectedTextIndex] = useState(null);
  const [textSettings, setTextSettings] = useState({});
  const [globalSettings, setGlobalSettings] = useState({
    strokeWidth: 10,
    textFillColor: "#FFFFFF",
    textStrokeColor: "#000000",
    selectedFont: '"Arial Black", Impact, sans-serif',
  });
  const [isGenerating, setIsGenerating] = useState(false);
  const [projectDialogOpen, setProjectDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const canvasRef = useRef(null);
  const { limits, fetchLimits } = useLimits();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmAction, setConfirmAction] = useState(null);

  // Load last used settings from localStorage
  const getLastUsedSettings = () => {
    try {
      const stored = localStorage.getItem("lastMemeSettings");
      if (stored) {
        return JSON.parse(stored);
      }
    } catch (error) {
      console.error("Error loading last meme settings:", error);
    }
    return {
      goal: "funny", // default goal
      description: "", // default description
    };
  };

  // Save settings to localStorage
  const saveLastUsedSettings = (goal, description) => {
    try {
      const currentSettings = getLastUsedSettings();
      localStorage.setItem(
        "lastMemeSettings",
        JSON.stringify({
          ...currentSettings,
          goal,
          description,
        })
      );
    } catch (error) {
      console.error("Error saving meme settings:", error);
    }
  };

  const fetchMemes = async (search = "") => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await generalApi.memes.fetchMemes(search);

      if (!response || !response.popularMemes || !response.memes) {
        throw new Error("Invalid meme data received");
      }

      setPopularMemes(response.popularMemes);
      setOtherMemes(response.memes);
    } catch (err) {
      const errorMessage =
        err instanceof Error
          ? err.message
          : "Failed to fetch memes. Please try again later.";
      setError(errorMessage);
      console.error("Error fetching memes:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetchMemes = useCallback(
    debounce((search) => fetchMemes(search), 300),
    []
  );

  useEffect(() => {
    document.title = "Browse Memes - Niche Toolbox";
    fetchMemes();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchMemes(searchTerm);
    } else {
      fetchMemes();
    }
  }, [searchTerm, debouncedFetchMemes]);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    fetchMemes();
  };

  const getLanguageName = (code) => {
    const language = languages.find((lang) => lang.code === code);
    return language ? language.name : "English";
  };

  const scrollToTop = () => {
    // Single smooth scroll with a delay
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "auto", // Changed to 'auto' for immediate scroll
      });
    }, 100);
  };

  const handleUseMeme = async (id, formData) => {
    try {
      setIsGenerating(true);

      // Clear previous state
      setSelectedMeme(null);
      setTextSettings({});
      setSelectedTextIndex(null);
      setEditorOpen(false);

      const meme = [...popularMemes, ...otherMemes].find((m) => m.id === id);
      if (!meme) return;

      // Scroll after clearing
      scrollToTop();

      // Set initial state
      const initialState = {
        id: id,
        preview: meme.imageUrl,
        textFields: {},
        formData,
      };

      setSelectedMeme(initialState);
      setEditorOpen(true);

      saveLastUsedSettings(formData.goal, formData.description);

      const generatedMeme = await generalApi.memes.generateMemeFromTemplate(
        meme.title,
        formData.description,
        getLanguageName(formData.language)
      );

      await fetchLimits();

      // Update with generated content
      setSelectedMeme({
        ...generatedMeme,
        id: id,
        preview: meme.imageUrl,
        formData,
        textType: generatedMeme.textType || "white",
      });
    } catch (error) {
      console.error("Error generating meme:", error);
      toast.error(error.message || "Failed to generate meme");
    } finally {
      setIsGenerating(false);
    }
  };

  const handleEditorClose = () => {
    // Don't close immediately if still generating
    if (isGenerating) return;

    setEditorOpen(false);
    setSelectedMeme(null);
  };

  const NoResultsMessage = () => (
    <Box sx={{ textAlign: "center", py: 8, color: "text.secondary" }}>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        No results found
      </Typography>
      <Typography sx={{ mt: 2 }}>
        Try adjusting your search or explore other memes
      </Typography>
    </Box>
  );

  // Update MemeCard to pass last used settings
  const renderMemeCard = (meme) => (
    <Grid item xs={12} sm={6} md={4} lg={3} key={meme.id}>
      <MemeCard
        id={meme.id}
        title={meme.title}
        description=""
        thumbnail={meme.imageUrl}
        onUse={(id, formData) => handleUseMeme(id, formData)}
        lastUsedSettings={getLastUsedSettings()}
      />
    </Grid>
  );

  // Initialize text settings when meme is selected
  useEffect(() => {
    if (selectedMeme?.textFields) {
      const initialTextSettings = {};
      Object.keys(selectedMeme.textFields).forEach((key) => {
        initialTextSettings[key] = {
          fontSize: 50,
          textAreaWidth: 100,
        };
      });
      setTextSettings(initialTextSettings);
    }
  }, [selectedMeme]);

  // Handle individual text settings changes
  const handleTextSettingChange = (textId, setting, value) => {
    setTextSettings((prev) => ({
      ...prev,
      [textId]: {
        ...prev[textId],
        [setting]: value,
      },
    }));
  };

  // Handle global settings changes
  const handleGlobalSettingChange = (setting, value) => {
    setGlobalSettings((prev) => ({
      ...prev,
      [setting]: value,
    }));
  };

  // Add new function to handle text content changes
  const handleTextContentChange = (textKey, newValue) => {
    if (textKey === "description") {
      // Update the formData description
      setSelectedMeme((prev) => ({
        ...prev,
        formData: {
          ...prev.formData,
          description: newValue,
        },
      }));
    } else {
      // Handle normal text field changes
      setSelectedMeme((prev) => ({
        ...prev,
        textFields: {
          ...prev.textFields,
          [textKey]: newValue,
        },
      }));
    }
  };

  const handleRegenerate = async () => {
    if (!selectedMeme) {
      console.log("No selected meme");
      toast.error("Cannot regenerate meme");
      return;
    }

    try {
      setIsGenerating(true);
      scrollToTop();

      // Use the current meme's data for regeneration
      const memeId = selectedMeme.id;
      const meme = [...popularMemes, ...otherMemes].find(
        (m) => m.id === memeId
      );

      if (!meme) {
        console.log("Template not found");
        toast.error("Template not found");
        return;
      }

      const description = selectedMeme.formData.description;

      const generatedMeme = await generalApi.memes.generateMemeFromTemplate(
        meme.title,
        description,
        getLanguageName(selectedMeme.formData.language)
      );

      await fetchLimits();

      setSelectedMeme((prev) => ({
        ...prev,
        ...generatedMeme,
        textType: generatedMeme.textType || "white",
      }));
      setIsGenerating(false);
      scrollToTop();
    } catch (error) {
      console.error("Error regenerating meme:", error);
      toast.error(error.message || "Failed to regenerate meme");
      setIsGenerating(false);
      scrollToTop();
    }
  };

  const handleDownload = () => {
    if (!canvasRef.current) {
      toast.error("Canvas not ready");
      return;
    }

    try {
      // Get the actual canvas element
      const canvas = canvasRef.current.getCanvas?.() || canvasRef.current;

      // Draw canvas without selection elements
      if (canvasRef.current.drawCanvasForDownload) {
        canvasRef.current.drawCanvasForDownload();
      }

      // Create and trigger download
      const link = document.createElement("a");
      link.download = `meme-${Date.now()}.png`;
      link.href = canvas.toDataURL("image/png");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Force a normal redraw after download
      if (selectedMeme) {
        setSelectedMeme({ ...selectedMeme }); // This will trigger a re-render with selection
      }

      toast.success("Meme downloaded successfully");
    } catch (error) {
      console.error("Error downloading meme:", error);
      toast.error("Failed to download meme");
    }
  };

  const handleAddToProject = () => {
    if (!selectedMeme?.formData?.language) {
      toast.error("Language not selected");
      return;
    }
    console.log("selectedMeme", selectedMeme);
    setProjectDialogOpen(true);
  };

  const handleNewMeme = () => {
    if (selectedMeme) {
      setConfirmAction("new");
      setShowConfirmDialog(true);
    }
  };

  const handleProjectSelect = async (project) => {
    // Check if project language matches meme language
    if (project.language !== selectedMeme?.formData?.language) {
      toast.error("Project language doesn't match meme language");
      return;
    }

    if (!project || !canvasRef.current) {
      toast.error("Cannot save meme at this time");
      return;
    }

    try {
      setIsUploading(true);
      const canvas = canvasRef.current;
      const blob = await new Promise((resolve) =>
        canvas.getCanvas().toBlob(resolve, "image/png")
      );
      const file = new File([blob], `meme-${Date.now()}.png`, {
        type: "image/png",
      });

      await generalApi.images.uploadImage(
        file,
        project._id,
        "meme",
        selectedMeme.formData?.description || "Generated meme"
      );

      toast.success(`Added to project: ${project.name}`);
      setProjectDialogOpen(false);
    } catch (error) {
      console.error("Error uploading to project:", error);
      toast.error(error.message || "Failed to add meme to project");
    } finally {
      setIsUploading(false);
    }
  };

  const handleBack = () => {
    if (selectedMeme) {
      setConfirmAction("back");
      setShowConfirmDialog(true);
    }
  };

  const handleConfirmAction = () => {
    setShowConfirmDialog(false);

    if (confirmAction === "back" || confirmAction === "new") {
      setSelectedMeme(null);
      setTextSettings({});
      setSelectedTextIndex(null);
      setEditorOpen(false);
    }

    setConfirmAction(null);
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ py: 4 }}>
        {selectedMeme ? (
          // Editor View
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mb: 4,
              }}
            >
              <Button
                onClick={handleBack}
                startIcon={<ArrowLeft size={18} />}
                sx={{
                  color: "#9c27b0",
                  "&:hover": { bgcolor: "rgba(156, 39, 176, 0.04)" },
                }}
              >
                Back
              </Button>
              {/* <Typography variant="h5" component="h1" sx={{ fontWeight: "bold" }}>
                Edit Meme
              </Typography> */}
            </Box>

            {/* Editor section */}
            <Box sx={{ mb: 6 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Typography
                  variant="h5"
                  component="h1"
                  fontWeight={600}
                  fontSize={26}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    fontWeight: 600,
                  }}
                >
                  <Sparkles size={24} style={{ color: "#9c27b0" }} />
                  Edit Meme
                </Typography>

                <Box sx={{ display: "flex", gap: 2 }}>
                  <Tooltip title="1 Credit" arrow>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleRegenerate}
                      startIcon={
                        isGenerating ? (
                          <CircularProgress size={18} />
                        ) : (
                          <Wand2 size={18} />
                        )
                      }
                      disabled={isGenerating}
                      sx={{
                        bgcolor: "#9c27b0",
                        "&:hover": { bgcolor: "#7b1fa2" },
                        textTransform: "none",
                      }}
                    >
                      Regenerate
                    </Button>
                  </Tooltip>

                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleDownload}
                    startIcon={<Download size={18} />}
                    sx={{
                      bgcolor: "#9c27b0",
                      "&:hover": { bgcolor: "#7b1fa2" },
                      textTransform: "none",
                    }}
                  >
                    Download
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleAddToProject}
                    sx={{
                      color: "#9c27b0",
                      borderColor: "#9c27b0",
                      bgcolor: "#fff",
                      "&:hover": {
                        bgcolor: "rgba(156, 39, 176, 0.04)",
                        borderColor: "#7b1fa2",
                      },
                      textTransform: "none",
                    }}
                  >
                    Add to Project
                  </Button>

                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleNewMeme}
                    startIcon={<PlusCircle size={18} />}
                    sx={{
                      color: "#9c27b0",
                      borderColor: "#9c27b0",
                      "&:hover": {
                        bgcolor: "rgba(156, 39, 176, 0.04)",
                        borderColor: "#7b1fa2",
                      },
                      textTransform: "none",
                    }}
                  >
                    New
                  </Button>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: 3,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  p: 3,
                  position: "relative",
                }}
              >
                {isGenerating && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.8)",
                      zIndex: 1,
                      borderRadius: 1,
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}

                {/* Left side - Editor */}
                <Box
                  sx={{
                    width: 300,
                    flexShrink: 0,
                  }}
                >
                  {!isGenerating && (
                    <MemeBrowseCanvas
                      textFields={selectedMeme.textFields}
                      textSettings={textSettings}
                      globalSettings={globalSettings}
                      selectedTextIndex={selectedTextIndex}
                      onTextSelect={setSelectedTextIndex}
                      onTextSettingChange={handleTextSettingChange}
                      onGlobalSettingChange={handleGlobalSettingChange}
                      fontOptions={fontOptions}
                      onTextContentChange={handleTextContentChange}
                      userInput={selectedMeme.formData?.description}
                    />
                  )}
                </Box>

                {/* Right side - Canvas */}
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#f5f5f5",
                    borderRadius: 1,
                    p: 2,
                    minHeight: 400,
                    "& canvas": {
                      maxWidth: "500px !important",
                      height: "auto !important",
                      objectFit: "contain",
                    },
                  }}
                >
                  {selectedMeme && selectedMeme.preview && (
                    <BrowseMemeCanvas
                      ref={canvasRef}
                      preview={selectedMeme.preview}
                      isTemplate={true}
                      templateData={{
                        ...selectedMeme,
                        textSettings,
                        globalSettings,
                      }}
                      selectedTextIndex={selectedTextIndex}
                      onTextSelect={setSelectedTextIndex}
                      onTextPositionChange={(newPositions) => {
                        setSelectedMeme((prev) => ({
                          ...prev,
                          coordinates: newPositions.coordinates,
                        }));
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          // Browse View
          <>
            <Typography
              variant="h5"
              component="h1"
              sx={{ fontWeight: "bold", mb: 4 }}
            >
              Browse & Create Memes
            </Typography>

            {/* Search and filters section */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 6,
              }}
            >
              <Box sx={{ position: "relative" }}>
                <TextField
                  placeholder="Search memes..."
                  value={searchTerm}
                  onChange={handleInputChange}
                  sx={{
                    width: "300px",
                    "& .MuiOutlinedInput-root": {
                      bgcolor: "white",
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.12)",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size={20} color="#666" />
                      </InputAdornment>
                    ),
                    endAdornment: searchTerm && (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={handleClearSearch}
                          size="small"
                        >
                          <X size={16} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>

            {isLoading ? (
              <Box sx={{ textAlign: "center", py: 4 }}>Loading memes...</Box>
            ) : error ? (
              <Box sx={{ textAlign: "center", py: 4 }}>
                <Typography color="error" sx={{ mb: 2 }}>
                  {error}
                </Typography>
                <Button
                  variant="outlined"
                  onClick={() => fetchMemes(searchTerm)}
                  sx={{ textTransform: "none" }}
                >
                  Try Again
                </Button>
              </Box>
            ) : popularMemes.length === 0 && otherMemes.length === 0 ? (
              <NoResultsMessage />
            ) : (
              <Box>
                {popularMemes.length > 0 && (
                  <Box sx={{ mb: 6 }}>
                    <Typography
                      variant="h5"
                      sx={{
                        mb: 3,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                      }}
                    >
                      <Flame
                        size={24}
                        style={{ color: "#ff6b6b", marginRight: "8px" }}
                      />
                      <span
                        style={{
                          background:
                            "linear-gradient(90deg, #ff6b6b 0%, #ee0979 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        Trending Memes
                      </span>
                    </Typography>
                    <Grid container spacing={3}>
                      {popularMemes.map(renderMemeCard)}
                    </Grid>
                  </Box>
                )}

                {otherMemes.length > 0 && (
                  <Box>
                    <Typography
                      variant="h5"
                      sx={{
                        mb: 3,
                        display: "flex",
                        alignItems: "center",
                        fontWeight: 600,
                      }}
                    >
                      <Sparkles
                        size={24}
                        style={{ color: "#9c27b0", marginRight: "8px" }}
                      />
                      <span
                        style={{
                          background:
                            "linear-gradient(90deg, #9c27b0 0%, #e91e63 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        Discover More Memes
                      </span>
                    </Typography>
                    <Grid container spacing={3}>
                      {otherMemes.map(renderMemeCard)}
                    </Grid>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Container>

      <ProjectSelector
        open={projectDialogOpen}
        onClose={() => setProjectDialogOpen(false)}
        onProjectSelect={handleProjectSelect}
        onAddProject={() => {
          setProjectDialogOpen(false);
        }}
        isUploading={isUploading}
        language={selectedMeme?.formData?.language}
      />

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>
          {confirmAction === "new" ? "Create New Meme?" : "Discard Changes?"}
        </DialogTitle>
        <DialogContent>
          Are you sure you want to{" "}
          {confirmAction === "new" ? "create a new meme" : "go back"}? Any
          unsaved changes will be lost.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmAction} sx={{ color: "#d32f2f" }}>
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BrowseMemes;
