import React from "react";
import { Box, TextField, Button } from "@mui/material";
import { Launch as LaunchIcon } from "@mui/icons-material";

const lengthOptions = [
  { value: 0, label: "Auto (Recommended)" },
  { value: 1, label: "Short" },
  { value: 2, label: "Medium" },
  { value: 3, label: "Long" },
];

const GenerateBlogPost = ({
  blogPost,
  onEdit,
  selectedProject,
  onRedirect,
  onGenerate,
  isGenerating,
  articleLength,
  setArticleLength,
}) => {
  const renderActionButton = () => {
    if (blogPost.savedPostId && selectedProject?._id) {
      return (
        <Button
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onRedirect(blogPost.savedPostId);
          }}
          startIcon={<LaunchIcon />}
          sx={{
            color: "#4caf50",
            borderColor: "#4caf50",
            "&:hover": {
              borderColor: "#45a049",
              backgroundColor: "rgba(76, 175, 80, 0.04)",
            },
            textTransform: "none",
            mt: 1,
          }}
        >
          View Saved Article
        </Button>
      );
    }

    return (
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation();
          onGenerate(blogPost);
        }}
        disabled={isGenerating || !selectedProject}
        sx={{
          bgcolor: "#9c27b0",
          "&:hover": { bgcolor: "#7b1fa2" },
          textTransform: "none",
          mt: 1,
        }}
      >
        {isGenerating ? "Generating..." : "Write Article"}
      </Button>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 1,
      }}
    >
      <TextField
        fullWidth
        multiline
        variant="standard"
        placeholder="Description"
        value={blogPost.description}
        onChange={(e) => onEdit("description", e.target.value)}
        onClick={(e) => e.stopPropagation()}
        InputProps={{
          disableUnderline: true,
          style: { fontSize: "0.9rem" },
          readOnly: !!blogPost.savedPostId,
        }}
        sx={{
          "& .MuiInputBase-root": {
            padding: 0,
            "&:hover": {
              bgcolor: blogPost.savedPostId
                ? "transparent"
                : "rgba(0, 0, 0, 0.04)",
            },
          },
        }}
      />

      {!blogPost.savedPostId && (
        <Box
          sx={{
            display: "flex",
            gap: 1,
            mt: 2,
            mb: 1,
          }}
        >
          {lengthOptions.map((option) => (
            <Button
              key={option.value}
              size="small"
              variant={
                articleLength === option.value ? "contained" : "outlined"
              }
              onClick={(e) => {
                e.stopPropagation();
                setArticleLength(option.value);
              }}
              sx={{
                flex: 1,
                minWidth: 0,
                bgcolor:
                  articleLength === option.value
                    ? "rgba(156, 39, 176, 0.08)"
                    : "transparent",
                borderColor:
                  articleLength === option.value
                    ? "#9c27b0"
                    : "rgba(0, 0, 0, 0.12)",
                color: "#9c27b0",
                "&:hover": {
                  bgcolor:
                    articleLength === option.value
                      ? "rgba(156, 39, 176, 0.12)"
                      : "rgba(156, 39, 176, 0.04)",
                  borderColor: "#9c27b0",
                },
                textTransform: "none",
                py: 0.5,
                fontSize: "0.875rem",
              }}
            >
              {option.label}
            </Button>
          ))}
        </Box>
      )}

      {renderActionButton()}
    </Box>
  );
};

export default GenerateBlogPost;
