import axios from "axios";
import config from "../config/env";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/translations";
import profileApi from "./profileApi";

const API_URL = config.API_URL;

// Create an axios instance with default configuration
const api = axios.create({
  baseURL: API_URL,
});

// Global state for subscription modal and limits refresh function
let showSubscriptionModal = null;
let refreshLimitsGlobal = null;

// Function to set the global showSubscriptionModal function
export const setGlobalShowSubscriptionModal = (modalFunction) => {
  showSubscriptionModal = modalFunction;
};

// Function to set the global refreshLimits function
export const setGlobalRefreshLimits = (refreshFunction) => {
  refreshLimitsGlobal = refreshFunction;
};

// Add a request interceptor to append the authorization header to all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle errors globally
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorMessage =
      getErrorMessage(error.response?.data?.message) || "An error occurred";

    if (error.response && error.response.status === 401) {
      // Dispatch authentication failure event
      window.dispatchEvent(new Event("authenticationFailed"));
      // Remove token from localStorage
      localStorage.removeItem("token");
      // Don't show the error toast for 401 errors as it will be handled by the auth failure event
    } else if (error.response && error.response.status === 402) {
      // Try to get the subscription URL and show modal
      try {
        const url = await profileApi.getWordPressLoginUrl();
        if (showSubscriptionModal) {
          showSubscriptionModal(url);
        }
      } catch (subscriptionError) {
        // If getting subscription URL fails, still show error message
        toast.error(getErrorMessage("ERROR_LOADING_BILLING_CONTACT_SUPPORT"));
      }
      // Don't show the error toast for 402 errors
    } else {
      // Instead of showing the toast here, we'll set a flag on the error object
      error.hasBeenHandled = true;
      error.handledMessage = errorMessage;
    }
    return Promise.reject(error);
  }
);

// Add these helper functions at the top of the file
const getToken = () => localStorage.getItem("token");

const handleApiError = (error, defaultMessage) => {
  const errorMessage = error.response?.data?.error || defaultMessage;

  switch (error.response?.status) {
    case 401:
      toast.error("Session expired. Please login again.");
      break;
    case 404:
      toast.error("Chat thread not found");
      break;
    case 429:
      toast.warning(
        "Rate limit reached. Please wait before sending more messages."
      );
      break;
    case 402:
      toast.error("Insufficient AI credits. Please upgrade your plan.");
      break;
    default:
      toast.error(errorMessage);
  }
};

const generalApi = {
  // Keyword related API calls
  keyword: {
    createKeyword: async (keyword) => {
      try {
        const response = await api.post("/project/keywords/create", keyword);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeywordsByProjectId: async (projectId) => {
      try {
        const response = await api.get(`/project/${projectId}/keywords`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeyword: async (keywordId) => {
      try {
        const response = await api.get(`/keywords/${keywordId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeywordForParasite: async (keywordId) => {
      try {
        const response = await api.get(`/parasite/${keywordId}`);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    updateKeyword: async (keywordId, keywordData) => {
      try {
        const response = await api.put(`/keyword/${keywordId}`, keywordData);
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteKeyword: async (keywordId) => {
      try {
        const response = await api.delete(`/keyword/${keywordId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteKeywords: async (keywordIds) => {
      try {
        const response = await api.post("/project/keywords/delete", {
          ids: keywordIds,
        });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getKeywordByProjectIdAndKeyword: async (projectId, keyword) => {
      try {
        const response = await api.get(
          `/keyword/project/${projectId}/keyword/${encodeURIComponent(keyword)}`
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteKeywordsByProjectId: async (projectId) => {
      try {
        const response = await api.delete(`/keyword/project/${projectId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    updateKeywordRefreshDate: async (keywordId) => {
      try {
        const response = await api.put(
          `/project/keywords/refresh/${keywordId}`
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  // Category related API calls
  category: {
    // getCategories: async () => {
    //   try {
    //     const response = await api.get("/general/categories");
    //     return response.data.results;
    //   } catch (error) {
    //     throw error;
    //   }
    // },

    getTrendingCategories: async () => {
      try {
        const response = await api.get("/general/trending-categories");
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getParasiteCategory: async (category) => {
      try {
        const response = await api.get(
          `/parasite/get-parasites-for-category/${category}`
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  // Project related API calls
  project: {
    createProject: async (projectData) => {
      try {
        const response = await api.post("/projects", projectData);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getProjects: async () => {
      try {
        const response = await api.get("/projects");
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getProjectsLists: async () => {
      try {
        const response = await api.get("/projects");
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    updateProject: async (projectData) => {
      try {
        const response = await api.put(
          `/projects/${projectData._id}`,
          projectData
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    deleteProject: async (projectId) => {
      try {
        const response = await api.delete(`/projects/${projectId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },

    getProjectById: async (projectId) => {
      try {
        const response = await api.get(`/projects/${projectId}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching project:", error);
        throw error;
      }
    },

    addBlogPost: async (blogPostData) => {
      try {
        const response = await api.post(`/blog-posts`, blogPostData);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        console.log("response:", response);
        return response.data;
      } catch (error) {
        console.error("Error adding blog post:", error);
        throw error;
      }
    },

    getBlogPost: async (blogPostId) => {
      try {
        const response = await api.get(`/blog-posts/${blogPostId}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching blog post:", error);
        throw error;
      }
    },

    updateBlogPost: async (blogPostId, blogPostData) => {
      try {
        const response = await api.put(
          `/blog-posts/${blogPostId}`,
          blogPostData
        );
        return response.data;
      } catch (error) {
        console.error("Error updating blog post:", error);
        throw error;
      }
    },

    deleteMeme: async (projectId, memeId) => {
      try {
        const response = await api.delete(
          `/projects/${projectId}/memes/${memeId}`
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error deleting meme:", error);
        throw error;
      }
    },

    deleteArticle: async (projectId, blogPostId) => {
      try {
        const response = await api.delete(
          `/projects/${projectId}/articles/${blogPostId}`
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error deleting article:", error);
        throw error;
      }
    },

    addBlogPostIdea: async (data) => {
      try {
        const response = await api.post("/blog-post-ideas", data);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error adding blog post idea:", error);
        throw error;
      }
    },

    addStatusPost: async (data) => {
      const response = await api.post("/status-posts", data);
      return response.data;
    },
  },

  city: {
    getCitiesByCountry: async (countryCode, searchEngine, search = "") => {
      try {
        const response = await api.get(
          `/cities/${countryCode}?searchEngine=${searchEngine}&search=${search}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching cities:", error);
        throw error;
      }
    },
  },

  // Keyword analysis
  getKeywordAnalysis: async (
    keyword,
    country,
    city,
    language,
    searchEngine,
    typeParams,
    keywordId = null
  ) => {
    try {
      const data = {
        keyword,
        country,
        city,
        language,
        searchEngine,
        ...typeParams,
      };
      if (keywordId) {
        data.keywordId = keywordId;
      }
      const response = await api.post("/parasite/keyword-analysis", data);
      if (refreshLimitsGlobal) {
        await refreshLimitsGlobal();
      }
      return response.data;
    } catch (error) {
      console.error("Error in keyword analysis:", error);
      throw error;
    }
  },

  bulkKeywordAnalysis: async (
    keywords,
    country,
    city,
    language,
    searchEngine,
    projectId,
    typeParams
  ) => {
    try {
      const response = await api.post("/parasite/bulk-keyword-analysis", {
        keywords,
        country,
        city,
        language,
        searchEngine,
        projectId,
        ...typeParams,
      });
      if (refreshLimitsGlobal) {
        await refreshLimitsGlobal();
      }
      return response.data;
    } catch (error) {
      console.error("Error in bulk keyword analysis:", error);
      throw error;
    }
  },

  aiOverview: {
    getAiOverview: async (keywordId) => {
      try {
        const response = await api.post("/ai-overview", { keywordId });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  /** Notification API endPoints */
  notifications: {
    getNotifications: async (page = 1, limit = 30) => {
      try {
        const response = await api.get(
          `/notifications?page=${page}&limit=${limit}`
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching notifications:", error);
        throw error;
      }
    },
    markNotificationAsSeen: async (notificationId) => {
      try {
        const response = await api.put(`/notifications/${notificationId}/seen`);
        return response.data;
      } catch (error) {
        console.error("Error marking notification as seen:", error);
        throw error;
      }
    },
    markGenaralNotificationAsSeen: async (notificationId) => {
      try {
        const response = await api.put(
          `/generalNotification/${notificationId}/seen`
        );
        return response.data;
      } catch (error) {
        console.error("Error marking notification as seen:", error);
        throw error;
      }
    },
    markAllJobNotificationsAsRead: async () => {
      try {
        const response = await api.post("/notifications/mark-all-read");
        return response.data;
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        throw error;
      }
    },
    markAllMarketingNotificationsAsRead: async () => {
      try {
        const response = await api.post("/generalNotification/mark-all-seen");
        return response.data;
      } catch (error) {
        console.error("Error marking all notifications as read:", error);
        throw error;
      }
    },
    toggleNotificationSeenStatus: async (notificationId, newSeenStatus) => {
      try {
        const response = await api.put(
          `/notifications/${notificationId}/toggle-seen`,
          { seenStatus: newSeenStatus }
        );
        return response.data;
      } catch (error) {
        console.error("Error toggling notification seen status:", error);
        throw error;
      }
    },
    toggleGeneralNotificationSeenStatus: async (
      notificationId,
      newSeenStatus
    ) => {
      try {
        const response = await api.put(
          `/generalNotification/${notificationId}/toggle-seen`,
          { seenStatus: newSeenStatus }
        );
        return response.data;
      } catch (error) {
        console.error(
          "Error toggling general notification seen status:",
          error
        );
        throw error;
      }
    },
  },

  rankTracking: {
    createTrackings: async (projectId, data) => {
      try {
        const response = await api.post(
          `/project/${projectId}/rank-tracking`,
          data
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error creating rank tracking:", error);
        throw error;
      }
    },

    getProjectTrackings: async (projectId) => {
      try {
        const response = await api.get(`/project/${projectId}/rank-tracking`);
        return response.data;
      } catch (error) {
        console.error("Error fetching project trackings:", error);
        throw error;
      }
    },

    getTrackingHistory: async (trackingId) => {
      try {
        const response = await api.get(`/rank-tracking/${trackingId}/history`);
        return response.data;
      } catch (error) {
        console.error("Error fetching tracking history:", error);
        throw error;
      }
    },

    deleteTracking: async (trackingId) => {
      try {
        const response = await api.delete(`/rank-tracking/${trackingId}`);
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error deleting tracking:", error);
        throw error;
      }
    },

    refreshTracking: async (trackingId) => {
      try {
        const response = await api.post(
          `/rank-tracking/${trackingId}/refresh`,
          {}
        );
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error refreshing tracking:", error);
        throw error;
      }
    },
  },

  memes: {
    fetchMemes: async (search) => {
      try {
        const queryString = search
          ? `/memes?search=${encodeURIComponent(search)}`
          : "/memes";
        const response = await api.get(queryString);

        // Add debug logging
        console.log("API Response:", response.data);

        // Validate response structure based on actual API response
        if (
          !response.data ||
          typeof response.data.error !== "boolean" ||
          !response.data.results
        ) {
          console.error("Invalid response structure:", response.data);
          throw new Error("Invalid response format from server");
        }

        // Extract memes from results
        const { popularMemes = [], memes = [] } = response.data.results;

        // Use the imageUrl directly from the API response without modification
        return {
          popularMemes: Array.isArray(popularMemes)
            ? popularMemes.map((meme) => ({
                id: meme.id,
                title: meme.title,
                imageUrl: meme.imageUrl, // Use the URL directly as provided by the API
                isPopular: meme.isPopular,
              }))
            : [],
          memes: Array.isArray(memes)
            ? memes.map((meme) => ({
                id: meme.id,
                title: meme.title,
                imageUrl: meme.imageUrl, // Use the URL directly as provided by the API
                isPopular: meme.isPopular,
              }))
            : [],
        };
      } catch (error) {
        console.error("Error fetching memes:", error);
        if (axios.isAxiosError(error)) {
          const message =
            error.response?.data?.message || "Failed to fetch memes";
          throw new Error(message);
        }
        throw error;
      }
    },

    generateMeme: async (imageBase64, prompt, language) => {
      try {
        const response = await api.post("/memes/create", {
          imageBase64,
          prompt,
          language,
        });

        if (!response.data.imageAnalysis || !response.data.memeText) {
          throw new Error("Invalid response format from server");
        }

        return response.data;
      } catch (error) {
        console.error("Error generating meme:", error);
        if (axios.isAxiosError(error)) {
          const message =
            error.response?.data?.error || "Failed to generate meme";
          throw new Error(message);
        }
        throw error;
      }
    },

    generateMemeFromTemplate: async (memeName, prompt, language = "en") => {
      try {
        const response = await api.post("/memes/generate", {
          memeName,
          prompt,
          language,
        });

        if (!response.data || !response.data.results) {
          throw new Error("Invalid response format from server");
        }

        return response.data.results;
      } catch (error) {
        console.error("Error generating meme from template:", error);
        if (axios.isAxiosError(error)) {
          const message =
            error.response?.data?.message || "Failed to generate meme";
          throw new Error(message);
        }
        throw error;
      }
    },
  },

  // Add new prompts API endpoints
  prompts: {
    generateStatusPosts: async (
      language,
      niche,
      additionalInstructions = ""
    ) => {
      try {
        const response = await api.post("/generate/status-posts", {
          language,
          niche,
          additionalInstructions,
        });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error generating status posts:", error);
        throw error;
      }
    },

    generateBlogTitles: async (
      language,
      niche,
      additionalInstructions = ""
    ) => {
      try {
        const response = await api.post("/generate/blog-titles", {
          language,
          niche,
          additionalInstructions,
        });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error generating blog titles:", error);
        throw error;
      }
    },

    generateBlogPost: async (
      language,
      title,
      additionalInstructions = "",
      length = 2,
      projectId
    ) => {
      try {
        const response = await api.post("/generate/blog-post", {
          language,
          title,
          additionalInstructions,
          length,
          projectId,
        });
        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }
        return response.data;
      } catch (error) {
        console.error("Error generating blog post:", error);
        throw error;
      }
    },
  },

  images: {
    uploadImage: async (file, projectId, type, memeText) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("projectId", projectId);
        formData.append("type", type);
        formData.append("memeText", memeText);

        const response = await api.post("/images/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        return response.data;
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    },
  },

  facebookAds: {
    createAd: async (data) => {
      try {
        const response = await api.post("/fb-ads/create", {
          isAiImageGeneration: data.isAiImageGeneration,
          imageBase64: data.imageBase64,
          niche: data.niche,
          prompt: data.prompt,
          language: data.language,
        });

        if (refreshLimitsGlobal) {
          await refreshLimitsGlobal();
        }

        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },

  // Chat Endpoints
  chat: {
    createThread: async (title = "New Chat") => {
      try {
        const response = await api.post("/chat/create-thread", { title });
        return response.data;
      } catch (error) {
        handleApiError(error, "Failed to create chat thread");
        throw error;
      }
    },

    getThreads: async () => {
      try {
        const response = await api.get("/chat/threads");
        // Response format from instructions:
        // [{
        //   _id, userId, assistantId, threadId, title,
        //   lastMessage, lastMessageAt, createdAt, updatedAt
        // }]
        return response.data;
      } catch (error) {
        handleApiError(error, "Failed to fetch chat threads");
        throw error;
      }
    },

    deleteThread: async (threadId) => {
      try {
        const response = await api.delete(`/chat/threads/${threadId}`);
        toast.success("Thread deleted successfully");
        return response.data;
      } catch (error) {
        handleApiError(error, "Failed to delete thread");
        throw error;
      }
    },

    getMessages: async (threadId) => {
      try {
        const response = await api.get(`/chat/threads/${threadId}/messages`);
        // Response format from instructions:
        // {
        //   data: [{
        //     id, object: "thread.message", created_at,
        //     thread_id, role, content: [{ type: "text", text: { value } }]
        //   }]
        // }
        return response.data;
      } catch (error) {
        handleApiError(error, "Failed to fetch messages");
        throw error;
      }
    },

    sendMessage: async (threadId, message) => {
      try {
        const response = await api.post("/chat/send-message", {
          threadId,
          message,
        });
        return response.data;
      } catch (error) {
        handleApiError(error, "Failed to send message");
        throw error;
      }
    },

    renameThread: async (threadId, title) => {
      try {
        const response = await api.put(`/chat/threads/${threadId}/rename`, {
          title,
        });
        return response.data;
      } catch (error) {
        handleApiError(error, "Failed to rename thread");
        throw error;
      }
    },
  },
};

export default generalApi;
