import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Box,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle as AccountCircleIcon,
  Person as PersonIcon,
  CreditCard as CreditCardIcon,
  ExitToApp as ExitToAppIcon,
  AttachMoney as AttachMoneyIcon,
  BugReport as BugReportIcon,
  ContactSupport as ContactSupportIcon,
} from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import logo from "../assets/mainLogo.png";
import Notifications from "./Notifications";
import { useResizeObserverFix } from "../hooks/useResizeObserverFix";
import profileApi from "../api/profileApi";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/translations";

function TopBar({
  isSidenavOpen,
  onMenuClick,
  isMobile,
  onLogout,
  notifications,
  setNotifications,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useResizeObserverFix();

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/profile");
    handleMenuClose();
  };

  const handleSubscription = async () => {
    try {
      const url = await profileApi.getWordPressLoginUrl();
      window.open(url, "_blank");
    } catch (error) {
      const errorMessage = getErrorMessage(
        "ERROR_LOADING_BILLING_CONTACT_SUPPORT"
      );
      toast.error(errorMessage);
    }
    handleMenuClose();
  };

  const handleAffiliates = async () => {
    try {
      const url = await profileApi.getWordPressLoginUrl();
      window.open(`${url}&redirect_to=https://billing.niche.sh/affiliate-dashboard/`, "_blank");
    } catch (error) {
      const errorMessage = getErrorMessage(
        "ERROR_LOADING_BILLING_CONTACT_SUPPORT"
      );
      toast.error(errorMessage);
    }
    handleMenuClose();
  };

  const handleSettings = () => {
    navigate("/settings");
    handleMenuClose();
  };

  const handleLogout = () => {
    onLogout();
    handleMenuClose();
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "#ffffff !important",
        boxShadow: "none",
        width: "100%",
        transition: "width 0.3s",
        color: "#000",
        borderBottom: "1px solid",
        borderColor: (theme) => theme.palette.divider,
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onMenuClick}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {(!isMobile || !isSidenavOpen) && (
              <>
                <Link to="https://app.niche.sh" rel="noopener noreferrer">
                  <img
                    src={logo}
                    alt="Logo"
                    style={{ height: "50px", width: "auto", cursor: "pointer" }}
                  />
                </Link>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 600,
                      color: "text.primary",
                      paddingTop: "0px",
                      marginLeft: "-10px",
                    }}
                  >
                    Niche Toolbox
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      color: "#5c016f",
                      marginTop: "-5px",
                      alignSelf: "flex-end",
                      fontWeight: 600,
                    }}
                  >
                    BETA
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Notifications
            notifications={notifications}
            setNotifications={setNotifications}
          />
          <IconButton
            color="inherit"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            sx={{
              "& svg": {
                width: 32,
                height: 32,
              },
              color: "text.secondary",
              "&:hover": {
                color: "#5c016f",
              },
            }}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                mt: 1.5,
                "& .MuiMenuItem-root": {
                  py: 1.5,
                  px: 2.5,
                },
              },
            }}
          >
            <MenuItem onClick={handleProfile}>
              <ListItemIcon>
                <PersonIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </MenuItem>
            <MenuItem onClick={handleSubscription}>
              <ListItemIcon>
                <CreditCardIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Subscription & Billing" />
            </MenuItem>
            <MenuItem onClick={handleAffiliates}>
              <ListItemIcon>
                <AttachMoneyIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Affiliate Dashboard" />
            </MenuItem>
            <MenuItem 
              component="a" 
              href="https://niche.sh/contact-us" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ListItemIcon>
                <BugReportIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Report a Bug" />
            </MenuItem>
            <MenuItem 
              component="a" 
              href="https://niche.sh/contact-us" 
              target="_blank" 
              rel="noopener noreferrer"
            >
              <ListItemIcon>
                <ContactSupportIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Contact" />
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="medium" />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopBar;
