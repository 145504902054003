import React, { useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  PlayCircleOutline as VideoIcon,
} from "@mui/icons-material";

const videos = [
  {
    title: "Niche Toolbox - Overview",
    embedCode: '<div style="padding:61.15% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1038542280?h=a50bc21c74&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Niche Toolbox - Overview"></iframe></div>',
  },
  {
    title: "Browse & Create Memes",
    embedCode: '<div style="padding:54.58% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1038542235?h=38c11af21b&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Browse &amp; Create Memes"></iframe></div>',
  },
  {
    title: "Meme Generator with Own Images",
    embedCode: '<div style="padding:54.58% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1038542257?h=8c40a9fc94&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Meme Generator with own images"></iframe></div>',
  },
  {
    title: "The Easiest Way To Write Blog Posts for Facebook",
    embedCode: '<div style="padding:59.52% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1038542312?h=a81bc44735&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="The Easiest Way To Write Blog Posts for Facebook"></iframe></div>',
  },
];

function VideoScreen() {
  const [selectedVideo, setSelectedVideo] = useState(videos[0]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        gap: 2,
        p: 2,
        pt: "32px !important",
        height: "calc(100vh - 100px)",
        bgcolor: "#f5f5f5",
        overflow: "hidden",
      }}
    >
      {/* Left Panel - Video List */}
      <Paper
        elevation={0}
        sx={{
          width: isMobile ? "100%" : "320px",
          minWidth: isMobile ? "100%" : "320px",
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          height: isMobile ? "auto" : "100%",
          overflow: "hidden",
          bgcolor: "#ffffff",
          border: "1px solid",
          borderColor: "#e0e0e0",
        }}
      >
        <Box sx={{ p: 2, pb: 0 }}>
          <Typography
            variant="h5"
            sx={{
              color: "#333",
              mb: 3,
              pt: 2,
              fontWeight: 600,
            }}
          >
            Video Training
          </Typography>
        </Box>
        <List
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            maxHeight: isMobile ? "300px" : "calc(100vh - 250px)",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              backgroundColor: "#9333EA",
            },
            "& .MuiListItem-root:hover": {
              backgroundColor: "#f5f5f5",
            },
          }}
        >
          {videos.map((video) => (
            <ListItem
              key={video.title}
              button
              onClick={() => setSelectedVideo(video)}
              selected={selectedVideo.title === video.title}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#f0f0f0",
                },
              }}
            >
              <ListItemIcon>
                <VideoIcon sx={{ color: "#666" }} />
              </ListItemIcon>
              <ListItemText
                primary={video.title}
                primaryTypographyProps={{ 
                  sx: { 
                    color: "#333",
                    fontSize: "0.9rem",
                    lineHeight: "1.3"
                  } 
                }}
              />
            </ListItem>
          ))}
        </List>
      </Paper>

      {/* Right Panel - Video Player */}
      <Card
        elevation={0}
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          bgcolor: "#ffffff",
          border: "1px solid",
          borderColor: "#e0e0e0",
        }}
      >
        <CardContent
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            p: 2,
            pt: 2,
            "&:last-child": { pb: 2 },
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#333",
              mb: 2,
              fontSize: "1.1rem",
              fontWeight: 600,
            }}
          >
            {selectedVideo.title}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              minHeight: 0,
              maxWidth: "1000px",
              margin: "0 auto",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
                width: "100%",
                maxHeight: "calc(100vh - 250px)",
                overflow: "hidden",
                bgcolor: "background.paper",
              }}
              dangerouslySetInnerHTML={{ __html: selectedVideo.embedCode }}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default VideoScreen;
