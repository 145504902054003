import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/toastify-custom.css";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";

import theme from "./theme";

import { AppProvider } from "./context/AppContext";
import { LimitsProvider, useLimits } from "./context/LimitsContext";

import authApi from "./api/authApi";
import TopBar from "./components/TopBar";
import Sidenav from "./components/Sidenav";
import Login from "./components/Login";
import PasswordReset from "./components/PasswordReset";
import ResetPasswordForm from "./components/ResetPasswordForm";
import AuthLayout from "./components/AuthLayout";
import Layout from "./components/Layout";
import Profile from "./components/Profile";
import ProjectScreen from "./components/Project/ProjectsScreen";
import { io } from "socket.io-client";

import config from "./config/env";
import Dashboard from "./components/Dashboard";
import BrowseMemes from "./components/memegenerator/browse/BrowseMemes";
import MemeGenerator from "./components/memegenerator/MemeGenerator";
import MemeUpload from "./components/memegenerator/upload/MemeUpload";
import BlogPostIdeas from './components/blogpostideas/BlogPostIdeas';
import BlogPostEditor from "./components/blogpostideas/BlogPostEditor";
import VideoScreen from "./components/videoscreen/VideoScreen";
import SocialFirst from "./components/blogpostideas/SocialFirst";
import WritePost from "./components/blogpostideas/WritePost";
import StatusPostGenerator from "./components/statusposts/StatusPostGenerator";
import Settings from './components/Settings';
import SubscriptionModal from './components/common/SubscriptionModal';
import { setGlobalShowSubscriptionModal } from './api/generalApi';
// import FacebookAdCreator from './components/facebookad/FacebookAdCreator';
import FacebookAdUpload from './components/facebookad/upload/FacebookAdUpload';
// import FacebookAdCreate from './components/facebookad/create/FacebookAdCreate';
import Chat from "./components/Chat";

const SOCKET_BE_URL = config.SOCKET_BE_URL;

function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSidenavOpen, setIsSidenavOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { fetchLimits } = useLimits();
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState({
    jobNotifications: [],
    marketingNotifications: [],
  });
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);
  const [subscriptionUrl, setSubscriptionUrl] = useState('');

  useEffect(() => {
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;
    
    const connectSocket = () => {
      const token = localStorage.getItem("token");
      
      if (!token) {
        console.log("No authentication token found");
        return;
      }

      // Remove /api from the URL
      const baseUrl = SOCKET_BE_URL.replace(/\/api\/?$/, '').replace(/\/$/, '');
      
      console.log('Attempting to connect to:', baseUrl);

      const newSocket = io(baseUrl, {
        auth: {
          token: token,
        },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        transports: ['websocket', 'polling'],
        path: '/socket.io',
        autoConnect: false,
        withCredentials: true,
      });

      // Debug listeners
      newSocket.on("connect_error", (error) => {
        console.log("Connection error details:", {
          message: error.message,
          description: error.description,
          type: error.type,
          data: error.data,
          url: baseUrl,
          token: !!token
        });
        reconnectAttempts++;
        
        if (reconnectAttempts >= maxReconnectAttempts) {
          console.log("Max reconnection attempts reached");
          newSocket.disconnect();
        }
      });

      newSocket.on("error", (error) => {
        console.log("Socket error:", error);
      });

      newSocket.on("connect", () => {
        console.log("Connected successfully with socket ID:", newSocket.id);
        reconnectAttempts = 0;
      });

      newSocket.on("disconnect", (reason) => {
        console.log("Disconnected from socket server. Reason:", reason);
      });

      // Add debug logging before connection attempt
      console.log('Socket.IO configuration:', {
        url: baseUrl,
        path: '/socket.io',
        transports: ['websocket'],
        auth: { token: 'exists: ' + !!token }
      });

      // Manually connect
      newSocket.connect();

      newSocket.on("jobNotifications", (notification) => {
        setNotifications((prevNotifications) => ({
          ...prevNotifications,
          jobNotifications: [
            notification.results,
            ...prevNotifications.jobNotifications,
          ],
        }));
      });

      setSocket(newSocket);
      return newSocket;
    };

    const newSocket = connectSocket();

    return () => {
      if (newSocket) {
        console.log('Cleaning up socket connection');
        newSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuth = await authApi.checkAuthStatus();
        setIsAuthenticated(isAuth);
        setIsLoading(false);
      } catch (error) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };
    checkAuth();

    const handleAuthFailure = () => {
      setIsAuthenticated(false);
      // Redirect to login page
      window.location.href = '/login';
    };
    window.addEventListener("authenticationFailed", handleAuthFailure);

    // Set up periodic token validation
    const tokenValidationInterval = setInterval(checkAuth, 5 * 60 * 1000); // Check every 5 minutes

    return () => {
      window.removeEventListener("authenticationFailed", handleAuthFailure);
      clearInterval(tokenValidationInterval);
    };
  }, []);

  useEffect(() => {
    setGlobalShowSubscriptionModal((url) => {
      setSubscriptionUrl(url);
      setSubscriptionModalOpen(true);
    });
  }, []);

  // New useEffect for updating the tab title
  useEffect(() => {
    const path = location.pathname;
    let title = "Niche Toolbox";

    switch (path) {
      case "/login":
        title = "Login | Niche Toolbox";
        break;
      case "/register":
        title = "Register | Niche Toolbox";
        break;
      case "/reset-password":
        title = "Reset Password | Niche Toolbox";
        break;
      case "/blog-post-ideas":
        title = "Blog Post Ideas | Niche Toolbox";
        break;
      case "/social-first":
        title = "Social First | Niche Toolbox";
        break;
      case "/write-post":
        title = "Write Post | Niche Toolbox";
        break;
      case "/status-post-generator":
        title = "Status Post Generator | Niche Toolbox";
        break;
      case "/meme-generator":
        title = "Meme Generator | Niche Toolbox";
        break;
      case "/training":
        title = "Training | Niche Toolbox";
        break;
      case "/settings":
        title = "Settings | Niche Toolbox";
        break;
      default:
        if (path.startsWith("/social-first-blog-posts/")) {
          title = "Blog Post Editor | Niche Toolbox";
        } else if (path.startsWith("/dashboard/")) {
          title = "Keyword Analysis | Niche Toolbox";
        }
    }

    document.title = title;
  }, [location]);

  const handleLogin = (token) => {
    authApi.setAuthToken(token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    authApi.logout();
    setIsAuthenticated(false);
  };

  const handleSidenavToggle = () => {
    setIsSidenavOpen(!isSidenavOpen);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const showTopBar =
    isAuthenticated &&
    !["/reset", "/reset-password"].includes(location.pathname);

  return (
    <>
      <div className="App">
        {showTopBar && (
          <>
            <TopBar
              isSidenavOpen={isSidenavOpen}
              onMenuClick={handleSidenavToggle}
              onLogout={handleLogout}
              isMobile={isMobile}
              notifications={notifications}
              setNotifications={setNotifications}
            />
            <Sidenav
              open={isSidenavOpen}
              onClose={() => setIsSidenavOpen(false)}
              isMobile={isMobile}
            />
          </>
        )}
        <Routes>
          <Route
            path="/login"
            element={
              isAuthenticated ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <AuthLayout isAuthenticated={isAuthenticated}>
                  <Login onLogin={handleLogin} />
                </AuthLayout>
              )
            }
          />
          <Route
            path="/reset-password"
            element={<PasswordReset />}
          />
          <Route
            path="/reset"
            element={<ResetPasswordForm />}
          />
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <Layout isMobile={isMobile}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/projects" element={<ProjectScreen />} />
                    <Route path="/training" element={<VideoScreen />} />
                    <Route path="/profile" element={<Profile />} />
                    {/* <Route path="/settings" element={<Settings />} /> */}
                    <Route path="/meme-generator" element={<MemeGenerator />} />
                    <Route path="/meme-generator/upload" element={<MemeUpload />} />
                    <Route path="/meme-generator/browse" element={<BrowseMemes />} />
                    <Route path="/blog-post-ideas" element={<BlogPostIdeas />} />
                    <Route path="/social-first-blog-posts/:blogPostId" element={<BlogPostEditor />} />
                    <Route path="/social-first" element={<SocialFirst />} />
                    <Route path="/write-post" element={<WritePost />} />
                    <Route path="/status-post-generator" element={<StatusPostGenerator />} />
                    <Route path="/facebook-ad-creator" element={<FacebookAdUpload />} />
                    {/* <Route path="/facebook-ad-creator/upload" element={<FacebookAdUpload />} />
                    <Route path="/facebook-ad-creator/create" element={<FacebookAdCreate />} /> */}
                    <Route path="/nsl-chat" element={<Chat />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
        <SubscriptionModal 
          open={subscriptionModalOpen}
          onClose={() => setSubscriptionModalOpen(false)}
          subscriptionUrl={subscriptionUrl}
        />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </>
  );
}

function App() {
  return (
    <Router>
      <AppProvider>
        <LimitsProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app-wrapper">
              <div className="background-container"></div>
              <AppContent />
            </div>
          </ThemeProvider>
        </LimitsProvider>
      </AppProvider>
    </Router>
  );
}

export default App;
